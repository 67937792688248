import { graphql } from 'gatsby';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PageSection from 'ui-kit/page-section/page-section';

import UpdateLocationContent from 'display-components/birdi-discount/modal-contents/update-location.component';

// Components
import PageLayout from 'components/layouts/page/page.layout';
import PrescriptionErrorOrNoResults from 'components/prescription-error-or-no-results/prescription-error-or-no-results.component';

import { DiscountCardErrors, formatDrugNameForURL } from 'state/discount-card/discount-card.helpers';
import { setCurrentLocationAddress } from 'state/discount-card/discount-card.reducers';
import { discountCardSearchRoutine } from 'state/discount-card/discount-card.routines';
import {
    discountCardErrorSelector,
    discountCardIsLoadingSelector,
    discountCardUserLocationAddressSelector,
    discountCardUserLocationSelector
} from 'state/discount-card/discount-card.selector';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';

import { searchDrugsByNameParams, USPSPartialAddress } from 'types/discount-card';

import storageHelper from 'util/storageHelper';
import { ZipValidateResponse } from 'util/usps';

import { useAddressVerification } from 'hooks/useAddressVerification';

import { discountCardLegalPaths } from '../index';
// Styles
import './index.style.scss';

const BirdiNoDrugResults = ({ location, data }: { location: any; data: GatsbyTypes.BirdiNoDrugResultsQuery }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(location.search);
    const searchedDrugName = formatDrugNameForURL(urlParams.get('drugName') as string, false);
    const searchedZipCode = urlParams.get('zip');

    const { verifyZip } = useAddressVerification();

    const userLocationAddress = useSelector(discountCardUserLocationAddressSelector);
    const discountCardError = useSelector(discountCardErrorSelector);
    const zipCode = useSelector(discountCardUserLocationSelector);
    const isLoading = useSelector(discountCardIsLoadingSelector);
    const hasLocationError = discountCardError && discountCardError.messageText === DiscountCardErrors.locationNotFound;
    const hasLocation = userLocationAddress !== undefined;

    useEffect(() => {
        storageHelper.local.removeCurrentDrugInfo();
    }, []);

    const handleChangeAddress = () => {
        dispatch(
            openModalComponent({
                title: t('pages.discountCard.updateLocation.modal.title'),
                hasDefaultFooter: false,
                hasCustomContent: true,
                content: <UpdateLocationContent handleCloseModal={() => dispatch(closeModalComponent())} />,
                variation: 'small',
                isCentered: true,
                onClose: () => dispatch(closeModalComponent())
            })
        );
    };

    const handleSubmitSearch = useCallback(
        (drugName: string) => {
            if (!hasLocation) {
                dispatch(discountCardSearchRoutine.failure({ messageText: DiscountCardErrors.locationNotFound }));
                return;
            }

            const searchPayload: searchDrugsByNameParams = {
                drugName,
                zipCode: zipCode?.address?.zip
            };

            dispatch(discountCardSearchRoutine.trigger({ data: searchPayload }));
        },
        [hasLocation, zipCode]
    );

    // in cases where the zip is sent via parameter, it is necessary to update the address information
    useEffect(() => {
        if (searchedZipCode) {
            verifyZip({
                zip: searchedZipCode,
                onSuccess: (validationResponse: ZipValidateResponse) => {
                    if (validationResponse.address) {
                        const validationPayload: USPSPartialAddress = {
                            city: validationResponse.address.city,
                            state: validationResponse.address.state,
                            zip: validationResponse.address.zip
                        };
                        dispatch(
                            setCurrentLocationAddress({
                                address: validationPayload
                            })
                        );
                    }
                }
            });
        }
    }, []);

    return (
        <div className="birdi-drug-not-found-page">
            <PageLayout
                metaData={{ nodeTitle: t('Drug Not Found') }}
                isShortFooter={true}
                hideSpecialtyAddress={true}
                legalPaths={discountCardLegalPaths}
            >
                <PageSection>
                    <PrescriptionErrorOrNoResults
                        title={t('pages.discountCard.notFound.title', {
                            drugName: searchedDrugName
                        })}
                        subTitle={t('pages.discountCard.notFound.subTitle')}
                        variant={'not-found'}
                        onPrescriptionSearch={handleSubmitSearch}
                        isSearchLoading={isLoading}
                        hasLocationError={hasLocationError}
                        hasLocation={hasLocation}
                        locationAddress={userLocationAddress}
                        onChangeAddress={handleChangeAddress}
                        locationError={t('pages.discountCard.hero.noLocationWarning')}
                        inputError={t('pages.discountCard.hero.emptyInput')}
                    />
                </PageSection>
            </PageLayout>
        </div>
    );
};

export default BirdiNoDrugResults;

export const query = graphql`
    query BirdiNoDrugResults($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
